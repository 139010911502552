import {
    styled,
    Typography,
} from '@mui/material';
import type { ProjectDto } from '@uipath/aifabric';
import {
    Field,
    Formik,
} from 'formik';
import { Switch } from 'formik-mui';
import React, {
    useContext,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
    useParams,
} from 'react-router-dom';
import * as Yup from 'yup';

import {
    createProject,
    updateProject,
} from '../../../api/client/projectManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import AccessibleFormikInput from '../../../components/AccessibleFormikInput';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormikErrorLabels from '../../../components/FormikErrorLabels';
import FormLayout from '../../../components/FormLayout';
import Label from '../../../components/Label';
import { WithVisibility } from '../../../components/WithVisibility';
import { Validation as val } from '../../../constants/validation';
import {
    ProjectsContext,
    UpdateType,
} from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import { extractErrorMessage } from '../../../utils/CommonUtils';

const ErrorLabel = styled('div')({
    marginTop: '5px',
    marginBottom: '5px',
});

const ProjectCreate: React.FC<{ isTenantMigrated: boolean }> = ({ isTenantMigrated }) => {
    const { t } = useTranslation();
    const feedback = useFeedback();
    const history = useHistory();
    const { projectName } = useParams<{ projectName: string }>();
    const [ projectNameState, setProjectNameState ] = useState('');
    const [ restrictAccessState, setRestrictAccessState ] = useState(false);
    const [ projectDesc, setProjectDesc ] = useState('');
    const [ projectId, setProjectId ] = useState<string | undefined>();
    const {
        state, actions,
    } = useContext(ProjectsContext);

    React.useEffect(() => {
        if (projectName && state.projects && state.projects.length) {
            const project = state.projects.find((proj: ProjectDto) => proj.name?.toLowerCase() === projectName.toLowerCase());
            setProjectNameState(project?.name || '');
            setProjectDesc(project?.description || '');
            setProjectId(project?.id || '');
            setRestrictAccessState(project?.restrictAccess || false);
        }
    }, [ state.currentProject, projectName, state.projects ]);

    return ((projectName && projectId) || !(projectName)) ? (
        <div
            data-testid="create-project-page"
            style={{ width: '100%' }}>
            <Formik
                initialValues={{
                    projectName: projectNameState,
                    projectDescription: projectDesc,
                    restrictAccess: restrictAccessState,
                }}
                enableReinitialize
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                onSubmit={async values => {
                    try {
                        if (projectId) {
                            await updateProject({
                                name: values.projectName,
                                description: values.projectDescription,
                                restrictAccess: values.restrictAccess,
                            }, projectId).then((updatedProject) => {
                                if (updatedProject) {
                                    /* Update list of store projects in store */
                                    actions.updateProjectsList(updatedProject, UpdateType.Edit);
                                    actions.setCurrent(projectName, updatedProject);
                                    feedback.enqueueSuccess(t('feedback_edit_success_project'));
                                }
                                return true;
                            });
                        } else {
                            await createProject({
                                name: values.projectName,
                                description: values.projectDescription,
                                restrictAccess: values.restrictAccess,
                            }).then((project) => {
                                if (project) {
                                    actions.updateProjectsList(project, UpdateType.Create);
                                    feedback.enqueueSuccess(t('feedback_create_success_project'));
                                }
                                return true;
                            });
                        }
                        history.push(generatePath(RoutePath.PROJECT_DASHBOARD, { projectName: values.projectName }));
                    } catch (error) {
                        feedback.enqueueError(extractErrorMessage(
                            error,
                            (projectName && state.currentProject?.id) ?
                                t('project_edit_default_error') :
                                t('project_create_default_error'),
                            {
                                10006: {
                                    0: 'Project',
                                    1: values.projectName,
                                },
                                20201: {},
                                20202: { 0: state?.currentProject?.id || '' },
                                20204: { 0: state?.currentProject?.id || '' },
                            },
                        ));
                    }
                }}
                validationSchema={Yup.object().shape({

                    projectName: Yup.string()
                        .required(t('project_create_projName_req'))
                        .matches(val.projectCreateNameValidation, t('project_name_validation_error'))
                        .max(val.projectCreateProjectNameMax,
                            t('project_create_projName_max', { max: val.projectCreateProjectNameMax })),

                    projectDescription: Yup.string()
                        .max(val.projectCreateDescriptionMax,
                            t('project_create_projDesc_max', { max: val.projectCreateDescriptionMax })),
                })}
            >
                {
                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                    props => {
                        const {
                            dirty,
                            isSubmitting,
                            handleSubmit,
                            submitForm,
                            setFieldValue,
                            errors,
                        } = props;

                        /* Set dirty check otherwise field value won't reflect */
                        if (projectId && props.values.projectName !== projectName && !dirty) {
                            setFieldValue('projectName', projectNameState);
                        }

                        /* Set dirty check otherwise field value won't reflect */
                        if (projectId && props.values.projectDescription !== projectDesc && !dirty) {
                            setFieldValue('projectDescription', projectDesc);
                        }

                        return (
                            <FormLayout
                                onSubmit={handleSubmit}
                                submitForm={submitForm}
                                footer={
                                    <FormButtonGroup
                                        dirty={dirty}
                                        isSubmitting={isSubmitting}
                                        submitButtonText={(projectId) ? t('form_edit_button_text') : undefined} />
                                }
                            >
                                <FormikErrorLabels errors={errors} />
                                <h2
                                    className='formTitle'
                                    aria-label={`${(projectId) ? t('project_edit_title') : t('project_create_title')} title`}>
                                    {(projectId) ? t('project_edit_title') : t('project_create_title')}
                                </h2>

                                <Label
                                    value={t('project_create_projName_label')}
                                    required
                                    aria-label={`${t('project_create_projName_label')} label`}>
                                    <ErrorLabel>
                                        <Typography>
                                            {t('project_name_validation_error')}
                                        </Typography>
                                    </ErrorLabel>
                                </Label>
                                <Field
                                    name="projectName"
                                    data-testid="project-name-input"
                                    type="text"
                                    component={AccessibleFormikInput}
                                    isValid={!errors['projectName']}
                                    requiredField
                                    className="textFeild min-width-override"
                                    data-cy="projectName"
                                    variant="outlined"
                                    color="secondary"
                                    placeholder={t('project_create_projName_ph')}
                                    aria-label={`${t('project_create_projName_label')} text field`}
                                    autoComplete="off"
                                    disabled={(projectId) ? true : false || isSubmitting}
                                />

                                <Label
                                    value={t('project_create_projDesc_label')}
                                    aria-label={`${t('project_create_projDesc_label')} label`} />
                                <Field
                                    name="projectDescription"
                                    data-testid="project-description-input"
                                    type="text"
                                    component={AccessibleFormikInput}
                                    className="min-width-override"
                                    variant="outlined"
                                    color="secondary"
                                    placeholder={t('project_create_projDesc_ph')}
                                    aria-label={`${t('project_create_projDesc_label')} text field`}
                                    autoComplete="off"
                                    disabled={isSubmitting}
                                    multiline
                                    rows={6}
                                />

                                <WithVisibility visible={isTenantMigrated} >
                                    <Label value={t('project_create_projAccess_label')} />
                                    <div aria-label={`${t('project_create_projAccess_label')} switch ${props.values.restrictAccess ? 'checked' : 'unchecked'}`}>
                                        <Field
                                            name="restrictAccess"
                                            component={Switch}
                                            color="secondary"
                                            disabled={isSubmitting}
                                            type="checkbox"
                                            inputProps={{ 'aria-label': t('project_create_projAccess_label') }}
                                        />
                                    </div>
                                </WithVisibility>
                            </FormLayout>
                        );
                    }
                }
            </Formik>
        </div>
    ) : null;
};

export default connect((state: any) => ({ isTenantMigrated: state.config.isTenantMigrated }))(ProjectCreate);

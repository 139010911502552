import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface NumberBadgeProps {
    title: string;
    value: number;
    testId?: string;
}

const NumberBadgeContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.semantic.colorBackgroundSecondary,
    width: '100%',
    height: '80px',
    marginLeft: '8px',

    '& .description': {
        paddingTop: '6px',
        fontWeight: Tokens.FontFamily.FontWeightDefault,
        fontSize: Tokens.FontFamily.FontSSize,
        lineHeight: Tokens.FontFamily.FontSLineHeight,
        fontFamily: Tokens.FontFamily.FontNormal,
        color: theme.palette.semantic.colorForegroundDeEmp,
    },

    '& .number': {
        paddingTop: '12px',
        fontWeight: Tokens.FontFamily.FontWeightDefault,
        fontSize: Tokens.FontFamily.FontHeader2Size,
        lineHeight: Tokens.FontFamily.FontHeader2LineHeight,
        fontFamily: Tokens.FontFamily.FontNormal,
        color: theme.palette.semantic.colorForeground,
    },
}));

const NumberBadge: React.FC<NumberBadgeProps> = ({
    title, value, testId,
}) => {
    const { t } = useTranslation();
    return (
        <NumberBadgeContainer
            data-testid={testId}
            aria-label={t('a11y_numeric_info')}>
            <div
                data-testid="number-badge-description"
                className="description"
                aria-label={t('a11y_label')}>
                {title}
            </div>
            <div
                data-testid="number-badge-value"
                className="number"
                aria-label={t('a11y_value')}>
                {value}
            </div>
        </NumberBadgeContainer>
    );
};

export default NumberBadge;
